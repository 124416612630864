<template>
  <div>
    <div>
      <b-navbar toggleable="lg" type="light">
        <b-container>
          <b-navbar-brand to="/login"
            >
             <div class="d-flex align-items-center">
            <img src="@/assets/images/pages/MyPals_Logo_Dark.svg" width="auto" height="44" alt="MyPals" />
          <!-- <h3 class="mb-0 ml-2 text-primary logo-title-red">MyPals</h3> -->
             </div>
          </b-navbar-brand>

          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

          <b-collapse id="nav-collapse" is-nav>
            <!-- Right aligned nav items -->
            <b-navbar-nav class="ml-auto">
              <b-nav-item class="mr-2" to="/login">Home</b-nav-item>
              <b-nav-item class="active mr-2" to="/howitworks"
                >How It Works</b-nav-item
              >

              <b-button
                class="login-button"
                variant="primary"
                href="javascript:void(0);"
                @click="showLogin"
                data-toggle="modal"
                data-target="#loginModal"
                >Log In</b-button
              >
            </b-navbar-nav>
          </b-collapse>
        </b-container>
      </b-navbar>
    </div>

    <div class="partnerwithus-banner">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="">
              <h1 class="text-white">
             Join MyPals to convert your thoughts into art and mint them as NFTs in one click. <br>
              Join our community now!</h1>
              <!-- <p class="text-white lead">
                Connect with like-minded people, create and sell your NFTs all in one place.
              </p> -->
              <a
                class="btn btn-light btn-lg mt-15"
                href="javascript:void(0);"
                @click="showSignup"
                data-toggle="modal"
                data-target="#signupModal"
                >Sign Up Now</a
              >
            </div>
          </div>
          <div class="col-lg-6 mt-30 mt-lg-0">
            <div class="video-gif">
                <img src="@/assets/images/pages/NFTs.png" class="img-fluid" alt="MyPals" />
             </div>

            <!-- <div class="embed-video-div">
              <div class="embed-responsive embed-responsive-16by9">
            <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/0hG8j-NoNwM?playlist=0hG8j-NoNwM&loop=1&autoplay=0&mute=1&rel=0" title="MyPals" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div> -->

          </div>
        </div>
      </div>
    </div>

    <section class="bg-white bg-pattern">
      <div class="container">
        <div class="row justify-content-center text-center">
          <div class="col-lg-9 section-heading">
            <h2 style="font-size: 30px">
              With MyPals, unleash your creativity without limits.
            </h2>
            <p class="lead"></p>
          </div>
        </div>
        <ul class="row list-inline u-info-v9-1 mb-0">
          <li class="col-lg-4 list-inline-item cc-mx-0 mb-5 mb-md-0 mb-lg-0">
            <div class="hiw-process text-center cc-px-40">
              <div class="process-image">
                <img
                  src="@/assets/images/pages/new/1.png"
                  alt="Connect & Build Your Network"
                />
              </div>
              <div class="mb-4">
                <span class="process-icon cc-bg-primary">
                  <span class="material-icons">done</span>
                </span>
              </div>
              <h4>Join Our Community</h4>
              <p>
                Connect with digital creators and collectors to exchange arts & ideas!
              </p>
            </div>
          </li>

          <li class="col-lg-4 list-inline-item cc-mx-0 mb-5 mb-md-0 mb-lg-0">
            <div class="hiw-process text-center cc-px-40">
              <div class="process-image">
                <img
                  src="@/assets/images/pages/new/4.png"
                  alt="Join communities"
                />
              </div>
              <div class="mb-4">
                <span class="process-icon cc-bg-primary">
                  <span class="material-icons">done</span>
                </span>
              </div>
              <h4>For Creators </h4>
              <p>
                Start creating beautiful NFT art using AI and share your creativity.
              </p>
            </div>
          </li>

          <li class="col-lg-4 list-inline-item cc-mx-0">
            <div class="hiw-process text-center cc-px-40">
              <div class="process-image">
                <img
                  src="@/assets/images/pages/new/3.png"
                  alt="For Collectors"
                />
              </div>
              <div class="mb-4">
                <span class="process-icon cc-bg-primary">
                  <span class="material-icons">done</span>
                </span>
              </div>
              <h4>For Collectors</h4>
              <p>
          Connect with verified creators & collect NFTs!
              </p>
            </div>
          </li>
        </ul>
      </div>
    </section>

    <section id="cd-timeline" class="bg-white">
      <div class="container cd-container">
        <div class="row justify-content-center text-center">
          <div class="col-lg-10 section-heading">
            <h2>How It Works?</h2>
            <p class="lead">
             Start your NFT journey with us using our most advanced NFT platform powered with Blockchain & AI.

            </p>
          </div>
        </div>

        <div class="cd-timeline-block">
          <div class="cd-timeline-img-lg">
            <img
              src="@/assets/images/pages/new/1.png"
              class=""
              alt="Connect and Network"
            />
          </div>

          <div class="cd-timeline-img cd-location">
            <span>1</span>
          </div>
          <div class="cd-timeline-content">
            <h3>Join our community</h3>
            <p>
             Join MyPals community where digital creators and collectors come together to exchange their arts as NFTs.<br>
             Connect with like-minded people to share your thoughts and ideas! 
            </p>
          </div>
        </div>

        <div class="cd-timeline-block">
          <div
            class="cd-timeline-img-lg cd-timeline-img-lg-2 cd-timeline-img-lg--custom position-absolute r-0"
          >
            <img
              src="@/assets/images/pages/new/4.png"
              class=""
              alt="Convert your arts to NFTs"
            />
          </div>

          <div class="cd-timeline-img cd-location">
            <span>2</span>
          </div>
          <div class="cd-timeline-content">
            <h3>Create your NFT arts seamlessly</h3>
            <p>
              Create stunning digital art using AI or upload your existing pieces and convert them into NFTs in just one click for free. Retain ownership of your artwork with the help of blockchain technology.
            </p>

          </div>
        </div>

        <div class="cd-timeline-block">
          <div class="cd-timeline-img-lg cd-timeline-img-lg-3">
            <img
              src="@/assets/images/pages/new/3.png"
              class=""
              alt="Build your portfolio of NFTs"
            />
          </div>

          <div class="cd-timeline-img cd-location">
            <span>3</span>
          </div>
          <div class="cd-timeline-content">
            <h3>Build your portfolio of NFTs</h3>
            <p>
              Connect with verified creators and collect beautiful NFT art to start building your portfolio on MyPals.
            </p>
            <p>Join MyPals now!</p>
           
            <a
              class="btn btn-primary btn-lg"
              href="javascript:void(0);"
              @click="showSignup"
              data-toggle="modal"
              data-target="#signupModal"
              >Sign Up Now</a
            >
          </div>
        </div>
      </div>
    </section>

    <footer class="footer-pages border-top">
      <div class="container">
        <div class="row" style="padding-bottom: 64px">
          <div class="col-lg-5 mb-5 mb-md-0 mb-lg-0">
            <div class="footer-info">
              <div class="d-flex align-items-center">
              <img
                src="@/assets/images/pages/MyPals_Logo_Dark.svg"

                width="auto"
                height="44"
                alt="MyPals"
              />
              <!-- <h3 class="mb-0 ml-2 text-primary logo-title-red">MyPals</h3> -->
              </div>
              <p class="mt-4">
                MyPals, a growing community of digital creators and collectors coming together to create and exchange NFT arts!
              </p>
            </div>
          </div>
          <div class="col-lg-3 mb-5 mb-md-0 mb-lg-0">
            <div class="footer-links">
              <h5>Helpful Links</h5>
              <ul class="list-unstyled">
                <li class="d-flex mb-2">
                  <span class="material-icons">chevron_right</span>
                  <router-link to="/login">
                    <a class="" href="#">Home</a>
                  </router-link>
                </li>
                
                <li class="d-flex mb-2">
                  <span class="material-icons">chevron_right</span>
                  <a class="" href="/howitworks">How It Works</a>
                </li>

              

                <li class="d-flex mb-2">
                    <span class="material-icons">chevron_right</span>
                <a class="" href="/legal">Legal</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="footer-contact">
              <h5>Contact Us</h5>
            <!--     <span class="mb-2">Phone: +61 0424910822</span>    -->
              <p>
                Email:
                <a class="" href="mailto:hello@mypals.app"
                  >hello@mypals.app</a
                >
              </p>
          <!--     <ul class="list-inline social-media">
                <li class="list-inline-item mr-3">
                  <a class="" href="https://www.facebook.com/coursepal"
                    ><i class="fa fa-facebook" aria-hidden="true"></i
                  ></a>
                </li>
                <li class="list-inline-item">
                  <a class="" href="https://wa.me/61424910822" target="_blank"
                    ><i class="fa fa-whatsapp" aria-hidden="true"></i
                  ></a>
                </li>
              </ul>      -->
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12 text-center border-top">
            <div class="copyright-pages">
              <span class="text-small"
                >© 2023 MyPals. All Rights Reserved.</span
              >
            </div>
          </div>
        </div>
      </div>
    </footer>

    

    <!--SignUp Modal -->
    <LoginModal />

    <RegisterModal />
    <!-- End SignUp Modal -->

    <!-- <div v-if="downloadApp">
      <InstallMyPalsApp />
    </div> -->

  </div>
</template>

<script>
import LoginModal from '@/layouts/components/coursepal/LoginModal'
import RegisterModal from './login/RegisterModal'
// import InstallMyPalsApp from '@/views/pages/InstallMyPalsApp'

export default {
  name: 'HowItWork',
  components: {
    LoginModal,
    RegisterModal,
    // InstallMyPalsApp
  },
  created() {
        this.appDownload();
       
    },
  methods: {
    showSignup () {
      this.$bvModal.hide('loginModal')
      this.$bvModal.show('signupModal')
    },
    showLogin () {
      this.email = ''
      this.password = ''
      this.$bvModal.hide('signupModal') 
      this.$bvModal.show('loginModal')
    },

    // Checking if the app is installed on the device.
        appDownload() {
            if ((navigator.userAgent.match(/iPhone/i)) || (navigator.userAgent.match(/iPod/i)) || (navigator.userAgent.match(/iPad/i))) {
                this.downloadApp = true
            }
            if (navigator.userAgent.match(/android/i)) {

               this.downloadApp = true
            }
        },
  }
}

</script>
<style scoped lang="scss" >
#page-login {
  .social-login-buttons {
    .bg-facebook {
      background-color: #1551b1;
    }
    .bg-twitter {
      background-color: #00aaff;
    }
    .bg-google {
      background-color: #4285f4;
    }
    .bg-github {
      background-color: #333;
    }
  }
}
body {
  font-family: "Open Sans", sans-serif !important;
  color: #626262;
  font-size: 16px !important;
  background-color: #fff !important;
}
p {
  font-size: 16px !important;
  margin-bottom: 1rem !important;
}
span {
  font-size: 16px !important;
}
.text-small {
  font-size: 14px !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #515365;
  /* color:#282828; */
  font-weight: 600;
}
h4,
h6 {
  margin-bottom: 0.5rem;
}
.lead {
  font-size: 20px !important;
}
.masthead-text {
  color: #fff;
  font-size: 21px !important;
}
.navbar-dark .navbar-nav .nav-link {
  font-size: 16px !important;
}
.vs-input--input,
.vs-select--input {
  border-radius: 0 !important;
}
.vs-input--label {
  font-size: 14px !important;
}

// Howitworks styles
.cd-timeline-img-lg {
  max-width: 40% !important;
}
.cd-timeline-img-lg img {
  max-width: 100% !important;
}
.cd-timeline-img-lg-2 {
  max-width: 38% !important;
}
.cd-timeline-img-lg-2 img {
  max-width: 100% !important;
}
.cd-timeline-img-lg-3 {
  max-width: 40% !important;
}
.cd-timeline-img-lg-3 img {
  max-width: 100% !important;
}
@media (min-width: 768px) {
  .cd-timeline-img span {
    font-size: 26px !important;
  }
}

// Zoho chat 
.zsiq_floatmain{
  visibility: hidden !important;
  display: none !important;
}
.zsiq_theme1 .zsiq_floatmain {
  display: none !important;
  visibility: hidden !important;
}
#zsiqwidget .zsiq_theme1.zsiq_floatmain {
  display: none !important;
  visibility: hidden !important;
}
.zsiq_floatmain {
  display: none !important;
  visibility: hidden !important;
}

</style>
