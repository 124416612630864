<template>
  <div>
    <!--Login Modal -->
    <b-modal id="loginModal" title hide-footer>
      <div class="modal-body">
        <div class="login-head text-center mb-4">
          <h3>Welcome to MyPals</h3>
          <p>Please log in to your account.</p>
        </div>
        <form>
          <div class="form-group">
            <vs-input
              v-validate="'required|email|min:3'"
              data-vv-validate-on="blur"
              name="email"
              icon-no-border
              icon="icon icon-user"
              icon-pack="feather" 
              label="Email"
              v-model="email"
              class="w-full"
            />
            <span class="text-danger text-sm">{{ errors.first("email") }}</span>
            <!-- <label for="">Email</label> -->
            <!-- <input type="email" class="form-control" id placeholder="Email" /> -->
          </div>
          <div class="form-group">
            <vs-input
              data-vv-validate-on="blur"
              v-validate="'required|min:6|max:30'"
              type="password"
              name="password"
              icon-no-border
              icon="icon icon-lock"
              icon-pack="feather"
              label="Password"
              v-model="password"
              class="w-full mt-6"
            />
            <span class="text-danger text-sm">{{
              errors.first("password")
            }}</span>
            <!-- <label for="">Password</label> -->
            <!-- <input type="password" class="form-control" id placeholder="Password" /> -->
          </div>

          <div class="form-group">
            <div class="d-flex justify-content-between">
              <router-link to="/forgot-password">Forgot Password?</router-link>
              
              <!-- <a href="#" class="text-small text-right">Forgot password?</a> -->
            </div>
          </div>

          <div class="form-group">
            <button
              type="button"
              :disabled="!validateForm"
              @click="login"
              class="btn btn-lg btn-primary btn-block"
            >
              Log In
            </button>
          </div>

          <div class="form-group">
            <p class="text-center">
              Don't have an account?
              <a
                href="javascript:void(0);"
                @click="showSignup"
                data-toggle="modal"
                data-target="#signupModal"
                >Sign up</a
              >
            </p>
          </div>
        </form>
        <div class="text-center mt-5 google-fb-sign">
          <img
            src="@/assets/images/pages/btn_google_signin_dark_pressed_web@2x.png" height="52"
            alt=""
            class="cursor-pointer"
            @click="loginWithGoogle"
          />
          </div>

          <div class="text-center mt-3 google-fb-sign">
          <img
           v-if='!isShow'
            src="@/assets/images/pages/apple-signin-btn.png"
            height="46"
            alt=""
            class="cursor-pointer"
            @click="loginWithApple"
          />
            
          <!-- <img
            src="@/assets/images/pages/FB.png"
            alt=""
            class="cursor-pointer ml-4"
            @click="loginWithFacebook"
          /> -->
        </div>
      </div>
    </b-modal>
    <!--End Login Modal -->
  </div>
</template>

<script>
export default {
  name: "LoginModal",
  data() {
    return {
      email: "",
      password: "",
      isShow:false,
    }
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.email !== "" && this.password !== ""
    }
  },
  created(){
        this.checkConnection()
    },
  methods: {
    checkConnection(){
                if (navigator.userAgent.match(/android/i)) {

                    this.isShow=true
                    console.log(this.isShow)
                }else{
                  this.isShow=false
                  console.log(this.isShow)
                }
    },

    showSignup() {
      this.$bvModal.hide("loginModal")
      this.$bvModal.show("signupModal")
    },
    login () {
      this.$vs.loading()
      const payload = {
        userDetails: {
          email: this.email.toLowerCase(),
          password: this.password
        },
        notify: this.$vs.notify,
        closeAnimation: this.$vs.loading.close,
        router: this.$router
      }
      this.$store.dispatch('auth/login', payload).then(() => this.$vs.loading.close())
    },
    loginWithFacebook () {
      this.$vs.loading()
      this.$store.dispatch('auth/loginWithFacebook')
    },
      loginWithApple () {
      this.$vs.loading()
      this.$store.dispatch('auth/loginWithApple')
    },

    loginWithGoogle () {
      this.$vs.loading()
      this.$store.dispatch('auth/loginWithGoogle')
    },

  }
}
</script>
